<template>
  <main v-if="customer">
    <PageTitleContainer><PageTitle>{{ customer.completeName }}</PageTitle></PageTitleContainer>

    <div>
      <p><strong>Name</strong>: {{ customer.name }}</p>
      <p><strong>Surname</strong>: {{ customer.surname }}</p>
      <p><strong>Type</strong>: {{ customer.type }}</p>
      <p><strong>Fiscal Code</strong>: {{ customer.fiscalCode }}</p>
      <p><strong>Vat</strong>: {{ customer.vat }}</p>
      <p><strong>Country</strong>: {{ customer.country }}</p>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  $api.fetchCustomer(to.params.id)
    .then(res => next(vm => {
      vm.customer = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      customer: null,
    };
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
